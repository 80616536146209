module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-KSDBH6M","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"route-change"},
    },{
      plugin: require('../node_modules/gatsby-v2-plugin-page-transitions/gatsby-browser.js'),
      options: {"plugins":[],"transitionTime":500},
    },{
      plugin: require('../node_modules/gatsby-plugin-scroll-reveal/gatsby-browser.js'),
      options: {"plugins":[],"threshold":0.8},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"bonsai gatsby starter","short_name":"bonsai","start_url":"/","background_color":"#ffffff","theme_color":"#ffffff","display":"minimal-ui","icon":"src/images/favicon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"f6456a106d9e87d37c4e0cf453fe3641"},
    },{
      plugin: require('../node_modules/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"omitPrismicScript":true,"repositoryName":"bonsaiwebsite","pages":[{"type":"Home","match":"/","component":"/opt/build/repo/src/templates/home.js"},{"type":"Successes","match":"/successes","component":"/opt/build/repo/src/templates/successes.js"},{"type":"About","match":"/about","component":"/opt/build/repo/src/templates/about.js"},{"type":"Project","match":"/successes/:uid","component":"/opt/build/repo/src/templates/project.js"},{"type":"Blog","match":"/blog","component":"/opt/build/repo/src/templates/blog.js"},{"type":"Post","match":"/blog/:uid","component":"/opt/build/repo/src/templates/post.js"}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
